import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

const useStyles = makeStyles<Theme>(({ palette, transitions }) => ({
  root: {
    display: 'inline-block',
    position: 'relative',
    zIndex: 1,

    '&::before, &::after': {
      animationDelay: ({ delay }: GlitchProps) => `${delay}s`,
      animationDuration: ({ duration }: GlitchProps) => `${duration}s`,
      animationIterationCount: 'infinite',
      animationTimingFunction: `cubic-bezier(0.44, 1.46, 0.9, -1.04)`,
      content: 'attr(data-title)',
      direction: 'inherit',
      display: 'block',
      height: '100%',
      left: 0,
      opacity: 0.8,
      position: 'absolute',
      top: 0,
      transition: `transform ${transitions.duration.shortest}ms`,
      unicodeBidi: 'inherit',
      width: '100%',
    },

    '&::before': {
      color: palette.primary.main,
      zIndex: -1,
    },

    '&::after': {
      color: palette.secondary.main,
      zIndex: -2,
    },
  },
  /** Animation types: */
  hover: {
    '.active &, &.active,': {
      '&::before': {
        transform: 'translate(-3px, -1px) skew(2deg)',
      },

      '&::after': {
        transform: 'translate(3px, 1px)  skew(-2deg)',
      },
    },
    '&:hover': {
      '&::before': {
        transform: 'translate(-3px, -1px) skew(2deg)',
      },

      '&::after': {
        transform: 'translate(3px, 1px)  skew(-2deg)',
      },
    },
  },
  animation: {
    '&::before': {
      animationDirection: 'reverse',
      animationName: '$glitch',
    },

    '&::after': {
      animationName: '$glitch',
    },
  },
  '@keyframes glitch': {
    '0%': {
      transform: 'translate(0)',
    },
    '2%': {
      transform: 'translate(-2px, 8px)',
    },
    '3%': {
      transform: 'translate(-8px, -2px)',
    },
    '4%': {
      transform: 'translate(-2px, -2px) scale(1.1) skew(1deg)',
    },
    '6%': {
      transform: 'translate(8px, 2px) scale(1.05) skew(3deg)',
    },
    '8%': {
      transform: 'translate(0)',
    },
    '90%': {
      transform: 'translate(0)',
    },
    '95%': {
      transform: 'translate(-3px, 3px)',
    },
    '96%': {
      transform: 'translate(-8px, -8px)',
    },
    '97%': {
      transform: 'translate(3px, -3px)',
    },
    '98%': {
      transform: 'translate(-8px, 3px)',
    },
    '99%': {
      transform: 'translate(4px, 16px)',
    },
  },
}));

interface GlitchProps {
  className?: string;
  delay?: number;
  duration?: number;
  type?: 'animation' | 'hover';
  component?(props: object): React.ReactElement;
}

const Glitch: React.FC<GlitchProps> = ({
  children,
  className = '',
  delay = 0,
  duration = 6,
  type = 'animation',
  component,
}) => {
  const classes = useStyles({ delay, duration });

  if (component) {
    return component({ children });
  }

  return (
    <span
      className={`${classes.root} ${classes[type]} ${className}`}
      data-title={children}
    >
      {children}
    </span>
  );
};

export default Glitch;
