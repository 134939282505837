import React, { useState } from 'react';
import { Variant } from '@material-ui/core/styles/createTypography';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Link from './Link';
import Glitch from './Glitch';

interface EmailProps {
  label?: React.ReactNode;
  email: string;
  glitch?: boolean;
  variant?: Variant;
}

const useStyles = makeStyles<Theme>(() => ({
  email: {
    direction: 'rtl',
    unicodeBidi: 'bidi-override',
  },
}));

const Email: React.FC<EmailProps> = ({
  email,
  glitch,
  variant = 'body1',
  label,
}) => {
  const classes = useStyles();
  const [mailto, setMailto] = useState('');
  const className = !label ? classes.email : undefined;
  const finalLabel = label ?? email.split('').reverse().join('');

  return (
    <Link
      href={`mailto:${mailto}`}
      noUnderline
      onMouseEnter={() => setMailto(email)}
      onMouseLeave={() => setMailto('')}
    >
      <Typography
        component={(props) =>
          glitch ? <Glitch {...props} /> : <span {...props} />
        }
        variant={variant}
        className={className}
      >
        <span>{finalLabel}</span>
      </Typography>
    </Link>
  );
};

export default Email;
