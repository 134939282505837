import React from 'react';
import EmailIcon from '@material-ui/icons/Email';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';

import Email from './Email';
import Social, { SocialUrl } from './Social';

const useStyles = makeStyles<Theme>(({ palette, typography, spacing }) => ({
  root: {
    background: 'transparent',
    bottom: 0,
    boxShadow: 'none',
    color: palette.common.white,
    fontWeight: typography.fontWeightBold,
    top: 'auto',
    position: 'relative',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: `${spacing(2)}px`,
  },
}));

interface FooterProps {
  authorLinks: SocialUrl[];
  email: string;
  position?: 'fixed' | 'static' | 'absolute';
}

const Footer: React.FC<FooterProps> = ({
  authorLinks,
  email,
  position = 'fixed',
}) => {
  const classes = useStyles();

  return (
    <AppBar
      className={classes.root}
      color="inherit"
      component="footer"
      position={position}
    >
      <Toolbar className={classes.toolbar}>
        <Email email={email} label={<EmailIcon />} />
        <Social data={authorLinks} />
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
