import React from 'react';
import makeStyles from '@material-ui/styles/makeStyles';
import type { Theme } from '@material-ui/core';
import { Facebook, LinkedIn, Twitter, GitHub } from '@material-ui/icons';

import Link from '../components/Link';

const iconsMap = {
  facebook: (props) => <Facebook {...props} />,
  github: (props) => <GitHub {...props} />,
  linkedin: (props) => <LinkedIn {...props} />,
  twitter: (props) => <Twitter {...props} />,
};

const useStyles = makeStyles<Theme>(({ spacing }) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    listStyle: 'none',
    gap: `${spacing(2)}px`,
  },
  link: {},
}));

export interface SocialUrl {
  href: string;
  label: string;
}

interface SocialProps {
  data: SocialUrl[];
  noLabels?: boolean;
}

const Social: React.FC<SocialProps> = ({ data, noLabels = true }) => {
  const classes = useStyles();

  return (
    <ul className={classes.root}>
      {data.map(({ href, label }) => {
        const Icon = iconsMap[label];

        return (
          <li key={label}>
            <Link
              className={classes.link}
              href={href}
              noUnderline
              rel="nofollow noopener noreferrer"
              target="_blank"
              title={label}
              aria-label={label}
            >
              {<Icon fontSize="small" />} {!noLabels && label}
            </Link>
          </li>
        );
      })}
    </ul>
  );
};

export default Social;
